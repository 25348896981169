import React, { Component } from "react";

import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import HomeDriveSection from "./HomeDriveSection";
import Footer from "../footer/Footer";

export class Home extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <HeroSection />
        <section className="gap"></section>
        <section className="container team-section">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="rbcard text-center">
                <img src="/assets/home/rb-camera.png" className="img-fluid" />
                <h4 className="rbcard-title">Design & Brand</h4>
                <p>
                  This is the company focued of the online media like Bhagya
                  Neupane. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="rbcard text-center">
                <img src="/assets/home/rb-live.png" className="img-fluid" />
                <h4 className="rbcard-title">Security</h4>
                <p>
                  This is the company focued of the online media like Bhagya
                  Neupane. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="rbcard text-center">
                <img src="/assets/home/rb-mic.png" className="img-fluid" />
                <h4 className="rbcard-title">Build & Update</h4>
                <p>
                  This is the company focued of the online media like Bhagya
                  Neupane. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="gap"></section>
        <HomeDriveSection />
        <section className="gap"></section>
        <Footer />
      </div>
    );
  }
}

export default Home;
