import './App.css'

import React, { Component } from 'react'

import News from './components/news/News';
import Home from './components/home/Home';
import NoPage from './components/nopage/NoPage';
import SignIn from './components/authentication/sign_in/SignIn';
import SignUp from './components/authentication/sign_up/SignUp';
import Team from './components/team/Team';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

class App extends Component {
  render() {
    return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route exact path="/news" element={<News />}/>
        <Route exact path="/teams" element={<Team />}/>
        <Route exact path="/sign-in" element={<SignIn />}/>
        <Route exact path="/sign-up" element={<SignUp />}/>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
    )
  }
}

export default App