import React, { Component } from "react";

export class HomeDriveSection extends Component {
  render() {
    return (
      <>
        <section className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-m-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
              <img src="/assets/home/girl-with-camera.png"/>
            </div>
            <div className="col-xl-6 col-lg-6 col-m-6 col-sm-12 col-xs-12 align-items-center justify-content-center">
              <p className="h3">Share you memory with us</p>
              <p className="mt-4">
                Share your story with the world! Send us your videos and
                content, and let your voice be heard. We love featuring the
                amazing content our community creates. Your perspective matters,
                and we can't wait to showcase your talent and experiences on our
                platform!
              </p>
              <button className="curve-button">Share Now <i class="fa-solid fa-share-nodes"></i></button>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HomeDriveSection;
