import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const navbarRef = useRef(null);
  const placeholderRef = useRef(null);
  let prevScrollPosition = 0;

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current;
      const placeholder = placeholderRef.current;
      const scrollPosition = window.scrollY;
      const showAt = window.innerHeight * 0.7;

      if (scrollPosition > prevScrollPosition) {
        // Scrolling down
        if (scrollPosition > showAt) {
          navbar.classList.add('bg-white');
          navbar.classList.remove('hidden');
          placeholder.classList.remove('hidden');
        } else {
          navbar.classList.remove('bg-white');
          navbar.classList.add('hidden');
          placeholder.classList.add('hidden');
        }
      } else {
        // Scrolling up
        if (scrollPosition === 0) {
          // At the top of the page
          navbar.classList.remove('bg-white');
        }

        if (scrollPosition === 0 || scrollPosition > showAt) {
          // At the top of the page or scrolling back up after 70%
          navbar.classList.remove('hidden');
          placeholder.classList.remove('hidden');
        } else {
          // Scrolling up within the 70% range
          navbar.classList.add('bg-white');
          navbar.classList.add('hidden');
          placeholder.classList.add('hidden');
        }
      }

      prevScrollPosition = scrollPosition;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand-lg py-4" ref={navbarRef}>
        <div className="navbar-placeholder" ref={placeholderRef}></div>
        <div className="container">
          <a className="navbar-brand me-auto" href="/">
            Rachanabachan
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse ms-auto" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/teams">
                  The Team
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/news">
                  Blogs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="#">
                  Our Publication
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex ms-auto">
            <Link className="curve-button" to="/sign-in">
              <i className="fa-regular fa-user fa-md"></i> Sign In
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
