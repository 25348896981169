import React, { Component } from "react";
import Navbar from "../home/Navbar";

export class Team extends Component {
  render() {
    return (
      <>
        <Navbar />
        <section className="auth-hero-section">
          <div id="carouselExampleSlidesOnly" class="container carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww" className="image-fluid"/>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <h2>Abishek Khanal</h2>
                        <h5>Software Developer</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww" className="image-fluid"/>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <h2>Sandharbha Dawadi</h2>
                        <h5>Website Developer</h5>
                        <p>asd asdlasdkas dlkasdnsakndaskd sakld alskdakd sadsakd</p>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww" className="image-fluid"/>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <h2>Krishna Gurung</h2>
                        <h5>Graphics Designer</h5>
                        <p>asd asdlasdkas dlkasdnsakndaskd sakld alskdakd sadsakd</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </>
    );
  }
}

export default Team;
