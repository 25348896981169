import React, { Component } from 'react'
import Navbar from '../../home/Navbar'
import { Outlet, Link } from "react-router-dom";



export class SignIn extends Component {
  render() {
    return (
      <div>
        <Navbar/>
        <section class="auth-hero-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6"> 
          <div class="auth-form-section">
            <h2>Sign In</h2>
            <form>
            <div class="mb-3">
                <label class="form-label">Email address</label>
                <input type="email" class="form-control" placeholder='Email address'/>
            </div>
            <div class="mb-3">
                <label class="form-label">Password</label>
                <input type="password" class="form-control" placeholder='Password'/>
            </div>
            <button type="submit" className="curve-button">Login</button>
            </form>
            <div className='d-flex justify-content-end'>
                <p>Don't have an account? <span><Link to="/sign-up">Create account</Link></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
      </div>
    )
  }
}

export default SignIn