import React, { Component } from "react";
import BackToTopButton from "../footer/BackToTop";

export class Footer extends Component {
  render() {
    return (
      <>
        <footer class="footer">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <p className="copyright-text">
                  &copy; 2024 SaAmNi Tech - All Right Reserved
                </p>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ul class="list-unstyled">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ul class="list-unstyled">
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Condition</a>
                  </li>
                  <li>
                    <a href="#"></a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="social-links">
                  <a href="">
                    <i class="fa-brands fa-facebook"></i>
                  </a>
                  <a href="">
                    <i class="fa-brands fa-youtube"></i>
                  </a>
                </div>
                <p className="mt-4">info@rachanabachan.com</p>
                <p>+977 9870989088</p>
              </div>
            </div>
          </div>
        </footer>
        <BackToTopButton />
      </>
    );
  }
}

export default Footer;
