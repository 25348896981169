import React, { Component } from 'react'

export class HeroSection extends Component {
  render() {
    return (
    <section class="hero-section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <h1>Welcome to Your Website!</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non mi luctus, faucibus libero ac, convallis nisl. Sed vestibulum nisl nec tellus venenatis, vel rhoncus purus scelerisque. Ut facilisis urna vel risus tincidunt, vel gravida lacus sodales. Integer tincidunt auctor urna, nec egestas sem vestibulum eu. Curabitur eleifend ex vel scelerisque tempus. Vestibulum eu metus vel mi luctus cursus.</p>
              <a href="#" class="curve-button">Learn More</a>
            </div>
            <div class="col-sm-6">
              <img src="https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?cs=srgb&dl=pexels-james-wheeler-414612.jpg&fm=jpg" alt="Hero image" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroSection