import React, { Component } from "react";
import Navbar from "../home/Navbar";
import Footer from "../footer/Footer";
import "./css/News.css";

export class News extends Component {
  render() {
    return (
      <>
        <Navbar />
        <section class="auth-hero-section">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-sm-6">
                <h1>Notice From Rachanabachan Family</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  non mi luctus, faucibus libero ac, convallis nisl. Sed
                  vestibulum nisl nec tellus venenatis, vel rhoncus purus
                  scelerisque. Ut facilisis urna vel risus tincidunt, vel
                  gravida lacus sodales. Integer tincidunt auctor urna, nec
                  egestas sem vestibulum eu. Curabitur eleifend ex vel
                  scelerisque tempus. Vestibulum eu metus vel mi luctus cursus.
                  Proin vel lacus nec mauris consectetur hendrerit ac eget
                  velit. Mauris tincidunt aliquam malesuada. In ullamcorper
                  facilisis magna vel vulputate. Donec facilisis nisi eu justo
                  finibus, non tincidunt nisl bibendum. Quisque fringilla
                  bibendum justo nec luctus. Sed sed ex id turpis feugiat
                  dapibus eget sit amet ligula. Sed hendrerit arcu vitae
                  pharetra convallis.
                </p>
                <a href="#" class="curve-button">
                  Read More
                </a>
              </div>
              <div class="col-sm-6 d-flex justify-content-center">
                <img
                  src="/assets/news/announcement.png"
                  alt="Hero image"
                  class="img-fluid"
                  style={{
                    width: "400px",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="gap"></section>
        <div className="container">
          <p className="h4">Recent Blogs</p>
          <div className="row mt-4">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <div className="rbcard">
                <img
                  src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D"
                  class="card-img-top"
                  alt="..."
                />
                <h5 class="rbcard-title">Card title</h5>
                <a href="#" class="curve-button">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="gap"></section>
        <Footer />
      </>
    );
  }
}

export default News;
