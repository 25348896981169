import React, { Component } from 'react';
import '../../BackToTopButton.css';

class BackToTopButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    const triggerScroll = 400;

    this.setState({
      isVisible: scrollPosition > triggerScroll,
    });
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { isVisible } = this.state;

    return (
      <button
        className={`back-to-top-button ${isVisible ? 'visible' : ''}`}
        onClick={this.scrollToTop}
      >
        <i class="fa-solid fa-angle-up"></i>
      </button>
    );
  }
}

export default BackToTopButton;
